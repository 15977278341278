@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsBold.ttf");
  font-family: "Poppins";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterRegular.ttf");
  font-family: "Inter";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsExtraBold.ttf");
  font-family: "Poppins";
  font-weight: 800;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/LexendRegular.ttf");
  font-family: "Lexend";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/LexendMedium.ttf");
  font-family: "Lexend";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/LexendBold.ttf");
  font-family: "Lexend";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsRegular.ttf");
  font-family: "Poppins";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsMedium.ttf");
  font-family: "Poppins";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/LexendExtraBold.ttf");
  font-family: "Lexend";
  font-weight: 800;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/LexendLight.ttf");
  font-family: "Lexend";
  font-weight: 300;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsSemiBold.ttf");
  font-family: "Poppins";
  font-weight: 600;
}
