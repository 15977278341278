body {
  margin: 0;
  padding: 0;
  font-family: Lexend;
}
* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}
a {
  display: block;
}
ul {
  margin: 0;
  margin-inline: 0 !important;
  padding: 0;
  list-style: none;
}
.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 10px;
}
input {
  background-color: transparent;
  width: 100%;
}
input:focus {
  outline: none;
}
.purple_400_light_blue_300_border {
  border-image: linear-gradient(180deg, #ce2acd, #5aebff) 1;
}
.purple_A100_02_cyan_A400_border {
  border-image: linear-gradient(180deg, #e071de, #02e4f0) 1;
}
.lime_500_33_lime_500_33_border {
  border-image: linear-gradient(180deg, #caff3333, #caff3333) 1;
}
@keyframes moveHorizontal {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-horizontal {
  animation: moveHorizontal 10s linear infinite; /* Adjust duration and timing function as needed */
}





